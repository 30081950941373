.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%; /* نسبت مورد نظر خود را اینجا قرار دهید */
    background: rgba(0, 0, 0, 0.5); /* یک رنگ یا تصویر دیگر برای بک‌گراند بالایی انتخاب کنید */
  }

  .header {
    background-image: url('./images/header.jpg');


    background-size: 100% 100% ; /* تنظیم این گزینه به cover باعث می‌شود تا تصویر به طور کامل در پس‌زمینه نمایش داده شود */
    background-position: center; /* تنظیم موقعیت تصویر به وسط */
    background-repeat: no-repeat; /* جلوگیری از تکرار تصویر */
    width: 100%; /* تنظیم عرض به 100% برای پر کردن تمام فضای ممکن */
    height: auto; /* تنظیم ارتفاع به خودکار برای حفظ نسبت ابعاد */
    min-height: 400;

  
  }
  
  