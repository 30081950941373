/* در فایل header.css */

  @font-face {
    font-family: BKoodakBold;
    src: url("./fonts/BKoodakBold.ttf") format("truetype");
  }

  body {
    background-image: url('./components/images/body.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  

